import { getAssetUrl } from '@mono/util/url';

export function HomepageOnboardingQuizAssetPath(fileName: string) {
  return getAssetUrl(`homepage/onboarding-quiz/${fileName}`);
}

export const quizImages = [
  HomepageOnboardingQuizAssetPath('career-interest.svg'),
  HomepageOnboardingQuizAssetPath('purpose.svg'),
  HomepageOnboardingQuizAssetPath('knowledge.svg'),
];

export const questionnaire = [
  {
    key: 'careerInterest',
    question: 'What do you want to learn about?',
    imageText: 'We have hundreds of courses that cover just about everything.',
    choices: [
      {
        id: 'Web Development',
        displayText: 'Web development',
      },
      {
        id: 'Data Science',
        displayText: 'Data science',
      },
      {
        id: 'Computer Science',
        displayText: 'Computer science',
      },
      {
        id: 'Web Design',
        displayText: 'Web design',
      },
      {
        id: 'Artificial Intelligence',
        displayText: 'Artificial intelligence',
      },
      {
        id: 'Machine Learning',
        displayText: 'Machine learning',
      },
      {
        id: 'Game Development',
        displayText: 'Game development',
      },
      {
        id: 'Mobile Development',
        displayText: 'Mobile development',
      },
      {
        id: 'Data Visualization',
        displayText: 'Data visualization',
      },
      {
        id: 'Cloud Computing',
        displayText: 'Cloud computing',
      },
      {
        id: 'Cybersecurity',
        displayText: 'Cybersecurity',
      },
      {
        id: "I don't know",
        displayText: 'Not sure yet',
      },
    ],
  },
  {
    key: 'purpose',
    question: 'What do you want to achieve?',
    imageText: 'Setting a goal makes you 40% more likely to stick with it.',
    choices: [
      {
        id: 'change',
        displayText: 'Switch careers',
      },
      {
        id: 'grow',
        displayText: 'Learn a skill for my job',
      },
      {
        id: 'build',
        displayText: 'Build a project',
      },
      {
        id: 'school',
        displayText: 'Learn for school',
      },
      {
        id: 'fun',
        displayText: 'Learn for fun',
      },
      {
        id: 'not_sure',
        displayText: 'Not sure yet',
      },
    ],
  },
  {
    key: 'knowledge',
    question: 'How much coding experience do you have?',
    imageText: 'Our hands-on learning environment is designed for all levels.',
    choices: [
      {
        id: 'Beginner',
        displayText: 'Beginner',
        descriptionText: "I've never coded, or I only know the basics.",
      },
      {
        id: 'Intermediate',
        displayText: 'Intermediate',
        descriptionText: "I've created some projects with code.",
      },
      {
        id: 'Advanced',
        displayText: 'Advanced',
        descriptionText: 'I have professional experience writing code.',
      },
    ],
  },
] as const;
