import {
  Box,
  Column,
  FlexBox,
  GridBox,
  LayoutGrid,
  Text,
} from '@codecademy/gamut';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { trackUserClick } from '../../tracking';
import { AnswerChoice } from './AnswerChoice';
import { BackButton } from './BackButton';
import {
  HomepageOnboardingQuizAssetPath,
  questionnaire,
  quizImages,
} from './consts';
import { useOnboardingContext } from './context/OnboardingContext';
import { scrollToAnchor } from './helpers';
import { LoadingCards } from './LoadingCards';
import { NumberedNavigator } from './NumberedNavigator';
import { PatternBorderContainer } from './PatternBorderContainer';
import { Results } from './Results';

const getGridCols = (
  choices: { id: string; displayText: string; descriptionText?: string }[]
) => {
  if (choices.length > 6) {
    return 'repeat(2, 1fr)';
  }
  return 'repeat(1, 1fr)';
};

const QuizImage = Box.withComponent('img');

interface AnonOnboardingQuestionnaireProps {
  setResultsOverlayIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resultsOverlayIsOpen: boolean;
  isUserInIndia: boolean;
}

export const AnonOnboardingQuestionnaire: React.FC<
  AnonOnboardingQuestionnaireProps
> = ({ setResultsOverlayIsOpen, resultsOverlayIsOpen, isUserInIndia }) => {
  const { currentIndex, isQuizComplete, quizAnswers, resetOnboardingContext } =
    useOnboardingContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_value, setLocalStorageValue, removeLocalStorageValue] =
    useLocalStorage('onboarding_quiz_answers', quizAnswers);

  // load images from static assets before page renders
  useEffect(() => {
    quizImages.forEach((src) => {
      const imageElement = new Image();
      imageElement.src = src;
      imageElement.width = 500;
      imageElement.height = 500;
    });
  }, []);

  useEffect(() => {
    removeLocalStorageValue();
  }, [removeLocalStorageValue]);

  useEffect(() => {
    if (isQuizComplete) {
      trackUserClick({
        context: 'onboarding_quiz',
        target: 'final_answer',
      });
      setLocalStorageValue(quizAnswers);
      setTimeout(() => setResultsOverlayIsOpen(true), 5000);
    }
  }, [
    isQuizComplete,
    quizAnswers,
    setLocalStorageValue,
    setResultsOverlayIsOpen,
  ]);

  // we only want to manage focus once the user has started interacting with the quiz
  const [hasStartedQuiz, setHasStartedQuiz] = useState(false);

  const focusTargetRef = useRef<HTMLDivElement | null>(null);

  // focus question text or loading message when switching questions or closing results modal
  useLayoutEffect(() => {
    if (hasStartedQuiz) {
      focusTargetRef.current?.focus({
        preventScroll: true,
      });

      if (typeof window !== 'undefined' && window.innerWidth < 768) {
        scrollToAnchor(focusTargetRef, -16);
      }
    }
  }, [currentIndex, isQuizComplete, hasStartedQuiz]);

  const currentQuestion = questionnaire[currentIndex];
  const questionType = currentQuestion.key;

  return (
    <PatternBorderContainer>
      <LayoutGrid columnGap={24}>
        <Column offset={{ xl: 1 }} gridColumnEnd={{ xl: 12 }} size={{ xl: 10 }}>
          <GridBox
            gridTemplateColumns={{ md: 'repeat(11, 1fr)' }}
            columnGap={24}
            bg="beige"
            py={48}
            px={24}
          >
            {isQuizComplete ? (
              <Column ref={focusTargetRef} tabIndex={-1}>
                <LoadingCards firstMessage="Picking recommendations based on your interests..." />
              </Column>
            ) : (
              <>
                <Column>
                  <Box textAlign={{ sm: 'center' }} pb={{ _: 48, sm: 64 }}>
                    <Text as="h2" pb={8}>
                      Find what&apos;s right for you
                    </Text>
                    <Text fontSize={18}>
                      Answer 3 quick questions to get recommendations that match
                      your interests.
                    </Text>
                  </Box>
                </Column>

                <Column>
                  <FlexBox flexDirection="row" width="100%">
                    <Box
                      data-testid="desktop-numbered-navigator"
                      display={{ _: 'none', sm: 'block' }}
                      pr={48}
                    >
                      <NumberedNavigator />
                    </Box>
                    <Box flexGrow={1}>
                      <Text
                        as="h2"
                        variant="title-sm"
                        mb={16}
                        ref={focusTargetRef}
                        tabIndex={-1}
                      >
                        {currentQuestion.question}
                      </Text>
                      <FlexBox
                        gap={{ md: 64, lg: 160 as 0, xl: 64 }}
                        alignItems="center"
                      >
                        <Box flexGrow={1}>
                          <GridBox
                            as="ul"
                            aria-label={`${currentQuestion.question} choices`}
                            gridTemplateColumns={{
                              _: 'repeat(1, 1fr)',
                              sm: getGridCols([...currentQuestion.choices]),
                            }}
                            columnGap={8}
                            rowGap={8}
                            pl={0}
                            mb={0}
                            style={{ listStyleType: 'none' }}
                          >
                            {currentQuestion.choices.map((choice) => (
                              <AnswerChoice
                                key={choice.id}
                                choice={choice}
                                questionType={questionType}
                                onClick={() => setHasStartedQuiz(true)}
                              />
                            ))}
                          </GridBox>
                        </Box>
                        <Box
                          display={{ _: 'none', md: 'flex' }}
                          flexDirection="column"
                          gap={40}
                          height={{ md: 250 }}
                          width={{ md: 350 }}
                          alignItems="center"
                          textAlign="center"
                        >
                          <Box style={{ pointerEvents: 'none' }}>
                            <QuizImage
                              src={HomepageOnboardingQuizAssetPath(
                                `${
                                  questionType === 'careerInterest'
                                    ? 'career-interest'
                                    : questionType
                                }.svg`
                              )}
                              height="auto"
                              maxWidth="100%"
                              aria-hidden
                            />
                          </Box>
                          <Box>
                            <Text width={{ _: 175, md: 274 }}>
                              {currentQuestion.imageText}
                            </Text>
                          </Box>
                        </Box>
                      </FlexBox>
                      {currentIndex > 0 && (
                        <FlexBox alignSelf="flex-start" mt={24}>
                          <BackButton questionType={questionType} />
                        </FlexBox>
                      )}
                    </Box>
                  </FlexBox>
                </Column>
              </>
            )}
          </GridBox>
        </Column>
      </LayoutGrid>
      {/* to maintain accessible DOM structure, we conditionally render the navigator below the question on mobile */}
      {!isQuizComplete ? (
        <Box
          data-testid="mobile-numbered-navigator"
          display={{ _: 'block', sm: 'none' }}
          position="relative"
          bg="beige"
          pb={48}
        >
          <NumberedNavigator />
        </Box>
      ) : null}
      <Results
        isOpen={resultsOverlayIsOpen}
        onRequestClose={() => {
          resetOnboardingContext();
          setResultsOverlayIsOpen(false);
          removeLocalStorageValue();
        }}
        quizAnswers={quizAnswers}
        isUserInIndia={isUserInIndia}
      />
    </PatternBorderContainer>
  );
};
