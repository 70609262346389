import { Box, FlexBox, Text, TextButton } from '@codecademy/gamut';
import { SparkleIcon } from '@codecademy/gamut-icons';
import {
  MarketingToaster,
  PricingSection,
  ProductFeatureShowcase,
} from '@mono/brand';
import { PricingSectionQuery } from '@mono/data/codegen/client/graphql-gateway/graphql';
import { GuardedCaptchaProvider } from '@mono/ui/captcha';
import { getAssetUrl, getJobReadinessPath } from '@mono/util/url';
import React, { useState } from 'react';

import { AnonOnboardingQuestionnaire } from './components/AnonOnboardingQuestionnaire';
import { OnboardingContextProvider } from './components/AnonOnboardingQuestionnaire/context/OnboardingContext';
import { BusinessBanner } from './components/BusinessBanner';
import { Credibility } from './components/Credibility';
import { CTABlock, CtaBlockData } from './components/CTABlock';
import { Goal, GoalSection } from './components/GoalSection';
import { HeroSignUpSection } from './components/HeroSignUpSection';
import { CarouselItem } from './components/HeroSignUpSection/Carousel/consts';
import { HikeSection } from './components/HikeSection';
import {
  LearnerStories,
  LearnerStoriesSection,
} from './components/LearnerStories';
import { PlatformShowcase } from './components/PlatformShowcase';
import { PlatformShowcaseSection } from './components/PlatformShowcase/consts';
import { trackUserClick } from './tracking';

export interface HomepageProps {
  contentfulData: {
    heroTitle: string;
    carousel: CarouselItem[];
    learnerStories: LearnerStoriesSection;
    goals: Goal[];
    platformShowcase: PlatformShowcaseSection;
    ctaBlock: CtaBlockData;
  };
  isUserInIndia: boolean;
  products?: PricingSectionQuery['pricing']['products'];
}

export const Homepage: React.FC<React.PropsWithChildren<HomepageProps>> = ({
  contentfulData,
  isUserInIndia,
  products,
  children,
}) => {
  const [resultsOverlayIsOpen, setResultsOverlayIsOpen] = useState(false);

  const homepageGoalsExist = !!contentfulData.goals.length;

  return (
    <GuardedCaptchaProvider>
      {children}
      <Box
        as="main"
        overflowX={{ _: 'hidden', md: 'initial' }}
        data-testid="homepage-container"
      >
        <MarketingToaster toasts={{}} />
        {/* when resultsOverlayIsOpen, the signup form is shown 2x on the homepage (the Hero one is hidden). This causes screen reader issue with duplicate forms */}
        {!resultsOverlayIsOpen && (
          <HeroSignUpSection
            title={contentfulData.heroTitle}
            carousel={contentfulData.carousel}
            isUserInIndia={isUserInIndia}
          />
        )}

        <OnboardingContextProvider>
          <AnonOnboardingQuestionnaire
            resultsOverlayIsOpen={resultsOverlayIsOpen}
            setResultsOverlayIsOpen={setResultsOverlayIsOpen}
            isUserInIndia={isUserInIndia}
          />
        </OnboardingContextProvider>

        <GoalSection goals={contentfulData.goals} />

        {isUserInIndia && (
          <>
            <HikeSection />
            <Credibility isUserInIndia={isUserInIndia} />
          </>
        )}
        <PlatformShowcase {...contentfulData.platformShowcase} />
        <ProductFeatureShowcase
          title="Job-readiness checker"
          titleBadge="Beta"
          eyebrow={
            <FlexBox>
              <SparkleIcon size={16} mr={8} />
              <Text variant="p-small" fontFamily="accent">
                Powered by AI
              </Text>
            </FlexBox>
          }
          description="See how well your skills and experience match the job postings you’re interested in. Our job-readiness checker uses artificial intelligence to show you what you need to work on to qualify for a role."
          image={{
            src: getAssetUrl('feature-marketing/job-readiness-checker.gif'),
            alt: '',
          }}
          cta={{
            label: 'Try it out',
            href: getJobReadinessPath(),
            onClick: () =>
              trackUserClick({
                page_name: 'homepage',
                context: 'try_it_out',
                target: 'job_readiness_checker',
              }),
          }}
        />

        {products && isUserInIndia && (
          <>
            <PricingSection
              products={products}
              title={
                <Text as="h2" variant="title-xl" my={48} textAlign="center">
                  Find a plan that fits your goals
                </Text>
              }
              ctaConfig={{
                'pro-silver': {
                  label: 'Join Plus',
                  href: '/subscriptions/proPaytmSilverAnnualV4/checkout',
                },
                'pro-gold': {
                  label: 'Join Pro',
                  href: '/subscriptions/proPaytmGoldAnnualV4/checkout',
                },
              }}
              isUserInIndia={isUserInIndia}
            />
            <FlexBox center pb={64}>
              <TextButton href="/pricing" role="button">
                View full pricing details -&gt;
              </TextButton>
            </FlexBox>
          </>
        )}

        <BusinessBanner />

        {!isUserInIndia && homepageGoalsExist && (
          <LearnerStories {...contentfulData.learnerStories} />
        )}

        {!isUserInIndia && <Credibility isUserInIndia={isUserInIndia} />}

        <CTABlock
          backgroundColor="hyper"
          ctaPosition="right"
          onCtaClick={() =>
            trackUserClick({
              context: 'homepage',
              target: 'get_started',
            })
          }
          ctaBlockData={contentfulData.ctaBlock}
        />
      </Box>
    </GuardedCaptchaProvider>
  );
};
