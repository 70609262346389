import {
  BasicInputType,
  Box,
  FormError,
  GridForm,
  Markdown,
  Text,
} from '@codecademy/gamut';

import { sendCaptchaGuardedRequest } from '@mono/ui/captcha';
import omit from 'lodash/omit';
import { useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import { RegisterFlow, businessSSOSignUpUrl } from '.';
import { LoginOrRegisterProps } from '../LoginOrRegister';
import { TrackingData } from '../LoginOrRegister/types';
import { SuccessCallbackProp } from '../LoginOrRegister/utils';
import { OauthButtons } from '../OAuthButtons';
import { TermsOfService } from '../TermsOfService';
import { trackUserClick } from '../tracking';
import { safeRedirectUrl } from '../url';
import { registerAccount } from './helpers';
import { SubmitValues, UserSubmitKey } from './types';
import { VALIDATORS } from './validators';

// standard
export const standardTitle = 'Create account';

// embeddable
export const embedTitle = 'Create free account';

export const passwordTips = `Make sure your password is at least 8 characters and contains:
- At least 1 uppercase letter and 1 lowercase letter
- At least 1 number
- At least 1 special character (like @#%^)

Avoid common passwords or strings like "password", "qwerty", or "12345".
`;

interface UserFormProps
  extends Pick<LoginOrRegisterProps, 'embedded' | 'variant'> {
  currentStep?: number;
  renderNextStep?: () => void;
  buttonText?: string;
  currentPage?: string;
  onSuccess: (body: SuccessCallbackProp) => void;
  redirectUrl?: string;
  reminderEmailData?: {
    content_path: string;
    content_title: string;
    content_pro_exclusive: boolean;
    content_is_path: boolean;
  };
  trackingData?: TrackingData;
  submissionError?: string;
  setErrorMsg: (errorMsg: string) => void;
  captureMobile?: boolean;
  onSubmit?: (isLogin: boolean) => void;
}

export const UserForm: React.FC<UserFormProps> = ({
  currentStep,
  renderNextStep,
  captureMobile,
  buttonText = 'Sign up',
  currentPage,
  onSuccess,
  redirectUrl,
  reminderEmailData,
  trackingData,
  submissionError,
  setErrorMsg,
  variant,
  onSubmit,
}) => {
  const [captcha, setCaptcha] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState(false);
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [whatsappOptIn, setWhatsappOptIn] = useState(true);
  const safeRedirect = redirectUrl && safeRedirectUrl(redirectUrl);

  const submitForm = (values: SubmitValues & { footer_ui: string }) => {
    onSubmit?.(false);
    setLoading(true);
    const { password, email, mobile: _mobile, footer_ui: _, ...rest } = values;
    const payload = {
      ...rest,
      user: {
        password,
        email,
        ...(userMobileNumber ? { mobile: userMobileNumber } : {}),
      },
      reminder_email: reminderEmailData,
      tracking_data: omit(trackingData, 'content_ids'),
    };

    const [captcha, captchaPromise] = sendCaptchaGuardedRequest({
      action: 'register_account',
      requestV2: (captchaToken) =>
        registerAccount({
          payload: {
            ...payload,
          },
          captchaVersion: 'v2',
          captchaToken,
        }),
      requestV3: (captchaToken) =>
        registerAccount({
          payload: {
            ...payload,
          },
          captchaVersion: 'v3',
          captchaToken,
        }),
      reCaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    });
    setCaptcha(captcha);
    captchaPromise
      .then((res) => {
        if (res.status === 'failed') {
          setErrorMsg(res.error.message || 'reCAPTCHA failed');

          setLoading(false);
        } else if (res.status === 'blocked') {
          setErrorMsg('Blocked by reCAPTCHA');
          setLoading(false);
        } else {
          const cookies = new Cookies();
          onSuccess({
            redirectTo: redirectUrl || '',
            authenticationToken: cookies.get('CSRF-TOKEN') as string,
            email: values.email,
          });
        }
        setCaptcha(null);
      })
      .catch(() => {
        setErrorMsg(
          'We were unable to complete your request. Please try again later.'
        );
        setCaptcha(null);
        setLoading(false);
      });
  };
  const submitMobileForm = (
    values: SubmitValues & { whatsapp_opt_in?: boolean }
  ) => {
    const ctaTrack = { target: 'continue_mobile' };
    trackUserClick({ ...ctaTrack, ...trackingData });
    const { mobile, whatsapp_opt_in } = values;
    setUserMobileNumber(mobile);
    setWhatsappOptIn(!!whatsapp_opt_in);
    renderNextStep?.();
  };

  const { emailValidation, passwordValidation, mobileValidation } = useMemo(
    () => ({
      emailValidation: VALIDATORS.email({
        errorMessages: {
          'SAML SSO Required': `Your company requires [sign-up via SAML SSO.](${businessSSOSignUpUrl(
            redirectUrl
          )})`,
        },
      }),
      passwordValidation: VALIDATORS.password({}),
      mobileValidation: VALIDATORS.mobile(),
    }),
    [redirectUrl]
  );

  const isFancy = variant === 'fancy';

  if (captureMobile && currentStep === RegisterFlow.MOBILE_NO_STEP) {
    return (
      <>
        <GridForm
          rowGap={8}
          fields={[
            {
              name: UserSubmitKey.MOBILE,
              label: 'Mobile number',
              type: 'tel' as BasicInputType,
              defaultValue: '',
              size: 12,
              validation: mobileValidation,
            },
            {
              defaultValue: true,
              description:
                'I want to receive regular updates on WhatsApp about new Codecademy features or promotions',
              id: 'whatsapp_opt_in-checkbox',
              hideLabel: true,
              name: 'whatsapp_opt_in',
              size: 12,
              type: 'checkbox',
            },
          ]}
          submit={{
            contents: 'Continue',
            position: 'stretch',
            size: 12,
            loading: ({ isSubmitting }) => isSubmitting || loading,
          }}
          validation="onTouched"
          onSubmit={submitMobileForm}
          data-testid="registration-form--mobile"
        />
        <Box pt={isFancy ? 0 : 16}>
          {!isFancy && submissionError && (
            <FormError>{submissionError}</FormError>
          )}
        </Box>
      </>
    );
  }

  return (
    <>
      <GridForm
        fields={[
          {
            name: UserSubmitKey.EMAIL,
            label: 'Email',
            type: 'email',
            defaultValue: '',
            size: 12,
            validation: emailValidation,
          },
          {
            name: UserSubmitKey.PASSWORD,
            label: 'Password',
            type: 'password',
            infotip: {
              info: isFancy ? (
                <Markdown
                  text={passwordTips}
                  overrides={{
                    p: {
                      component: (props) => (
                        <Text
                          as="p"
                          {...props}
                          lineHeight="title"
                          fontSize={16}
                        />
                      ),
                    },
                    li: {
                      component: (props) => (
                        <Text as="li" {...props} fontSize={16} />
                      ),
                    },
                  }}
                />
              ) : (
                <Markdown text={passwordTips} />
              ),
              alignment: 'bottom-left',
            },
            size: 12,
            defaultValue: '',
            validation: passwordValidation,
          },
          {
            name: 'referring_page',
            type: 'hidden',
            defaultValue: currentPage,
          },
          {
            name: 'redirect',
            type: 'hidden',
            defaultValue: safeRedirect,
          },
          {
            name: 'authenticity_token',
            type: 'hidden',
            defaultValue: '',
          },
          {
            name: UserSubmitKey.CODING_REMINDERS_COMMS_TIMEZONE,
            type: 'hidden',
            defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          {
            name: 'sweet_container',
            label: 'If you are a human, do not fill in this field.',
            type: 'sweet-container',
          },
        ]}
        submit={{
          contents: buttonText,
          position: 'stretch',
          size: 12,
          disabled: ({ isSubmitting }) => isSubmitting || loading,
        }}
        validation="onTouched"
        onSubmit={submitForm}
        data-testid="registration-form"
      />
      <Box pt={isFancy ? 0 : 16}>
        {!isFancy && submissionError && (
          <FormError>{submissionError}</FormError>
        )}
        {captcha}
      </Box>
      <TermsOfService
        color="navy-700"
        fontSize={12 as 14}
        lineHeight={isFancy ? 'title' : 'base'}
        mt={isFancy ? 4 : undefined}
      />
      <OauthButtons
        isFancy={isFancy}
        signUp
        trackingData={trackingData}
        urlParams={{
          redirectUrl,
          ...reminderEmailData,
        }}
        userContext={{
          mobile: userMobileNumber,
          whatsappOptIn,
        }}
      />
    </>
  );
};
