import { getAssetUrl } from '@mono/util/url';

export interface PlatformShowcaseImage {
  src: string;
  alt: string;
}
export interface PlatformFeature {
  title: string;
  description: string;
  image: PlatformShowcaseImage;
  trackingTitle: string;
}
export interface PlatformShowcaseCta {
  copy: string;
  href: string;
}
export interface PlatformShowcaseSection {
  title: string;
  eyebrow: string;
  platformFeatures: PlatformFeature[];
  ctas: PlatformShowcaseCta[];
}

export const platformShowcaseTrackingBase = {
  context: 'platform_showcase',
};

export function PlatformShowcaseAssetPath(fileName: string) {
  return getAssetUrl(`homepage/platform-showcase/${fileName}`);
}

export const defaultPlatformFeatures: PlatformFeature[] = [
  {
    title: 'AI-Assisted Learning',
    description:
      'Get coding help quickly and when you need it to speed up your learning journey. Our AI features help you understand errors and solution code faster and get personalized feedback.',
    image: {
      src: PlatformShowcaseAssetPath('ai_error_explain.gif'),
      alt: "Animated GIF of an AI provided error explanation within Codecademy's learning environment",
    },
    trackingTitle: 'ai_assisted_learning',
  },
  {
    title: 'Mobile IDE',
    description:
      'Continue your coursework when and where you work best. With our mobile-friendly IDE, you can code right in your browser from any device.',
    image: {
      src: PlatformShowcaseAssetPath('mobile-le.webp'),
      alt: "Mobile-friendly version of a lesson and code editor for the course 'Introduction to HTML' running in Codecademy's learning environment",
    },
    trackingTitle: 'mobile_ide',
  },
  {
    title: 'Portfolio Project Assistance',
    description:
      'Get unstuck while building projects with AI-generated hints and quick access to our community resources.',
    image: {
      src: PlatformShowcaseAssetPath('project-hints.webp'),
      alt: 'An AI-generated hint within the instructions of a Codecademy project',
    },
    trackingTitle: 'portfolio_project_assistance',
  },
  {
    title: 'Integrated Reference Docs',
    description:
      'With integrated Docs, you can get an explanation of a particular term or concept without having to leave the learning environment.',
    image: {
      src: PlatformShowcaseAssetPath('integrated_reference_docs.gif'),
      alt: 'Animated GIF of a mouse cursor hovering over the Python term "comment" displaying a Docs tooltip within a Codecademy lesson',
    },
    trackingTitle: 'integrated_reference_docs',
  },
  {
    title: 'Jupyter Notebook',
    description:
      'Work with tools professional Data Scientists use daily now that Jupyter Notebook is built right into select data science courses.',
    image: {
      src: PlatformShowcaseAssetPath('jupyter_notebook.gif'),
      alt: "Animated GIF of Jupyter notebook integrated within a course titled 'Merging Datasets' running in Codecademy's learning environment",
    },
    trackingTitle: 'jupyter_notebook',
  },
];

const defaultPlatformShowcaseCtas: PlatformShowcaseCta[] = [
  {
    copy: 'Get started',
    href: '/register',
  },
  { copy: 'Explore features', href: 'https://try.codecademy.com/ai' },
];

export const defaultPlatformShowcaseData: PlatformShowcaseSection = {
  title: 'Hands-on learning',
  eyebrow: 'The platform',
  platformFeatures: defaultPlatformFeatures,
  ctas: defaultPlatformShowcaseCtas,
};
