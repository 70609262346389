import { Box } from '@codecademy/gamut';
import cx from 'classnames';
import React from 'react';
import { useCookies } from 'react-cookie';

import { TrackingData } from '../LoginOrRegister/types';
import { trackUserClick } from '../tracking';
import { addParamsAsHash } from '../url';
import { OAuthButtonsWrapper, StyledStrokeButton } from './elements';
import { SocialLogo } from './logos';
import { OAuthHeader } from './utils';

export const appleAuthenticationUrl = '/users/auth/apple?signin=true';

export const githubAuthenticationUrl =
  '/users/auth/github?scope=public_repo,user:email';

export const googleAuthenticationUrl = '/users/auth/google_oauth2?signin=true';

export const facebookAuthenticationUrl = '/users/auth/facebook?signin=true';

export const twitterAuthenticationUrl = '/users/auth/twitter?signin=true';

const orgConfigs = {
  google: {
    org: 'Google',
    url: googleAuthenticationUrl,
    signInOnly: false,
  },
  facebook: {
    org: 'Facebook',
    url: facebookAuthenticationUrl,
    signInOnly: false,
  },
  github: {
    org: 'Github',
    url: githubAuthenticationUrl,
    signInOnly: false,
  },
  twitter: {
    org: 'Twitter',
    url: twitterAuthenticationUrl,
    signInOnly: true,
  },
  apple: {
    org: 'Apple',
    url: appleAuthenticationUrl,
    signInOnly: false,
  },
};

type Org = keyof typeof orgConfigs;

const defaultOrder: Org[] = [
  'google',
  'facebook',
  'github',
  'twitter',
  'apple',
];

const OAuthListItem = Box.withComponent('li');

export interface OauthButtonsProps {
  classNames?: {
    heading?: string;
    buttons?: string;
    button?: string;
    buttonTitle?: string;
    buttonContainer?: string;
  };
  isFancy?: boolean;
  order?: Org[];
  signUp?: boolean;
  titlePrefix?: string;
  trackingData?: TrackingData;
  // eslint-disable-next-line @typescript-eslint/ban-types
  urlParams?: {};
  userContext?: { mobile: string; whatsappOptIn?: boolean };
}

export const OauthButtons: React.FC<OauthButtonsProps> = ({
  classNames = {},
  isFancy,
  order,
  signUp,
  titlePrefix,
  trackingData,
  urlParams,
  userContext,
}) => {
  const [cookies] = useCookies();
  const urlParamsWithTrackingData = trackingData
    ? {
        ...urlParams,
        ...userContext,
        page_name: trackingData.page_name,
        context: trackingData.context,
      }
    : { ...urlParams, ...userContext };

  return (
    <Box>
      <OAuthHeader isFancy={isFancy} signUp={signUp} />
      <OAuthButtonsWrapper isFancy={isFancy}>
        <>
          {(order || defaultOrder).map((org) => {
            const orgConfig = orgConfigs[org];
            const crsfToken = cookies['CSRF-TOKEN'] as string;

            if (orgConfig.signInOnly && signUp) return null;

            return (
              <OAuthListItem
                key={orgConfig.url}
                width={isFancy ? '100%' : undefined}
              >
                <form
                  method="POST"
                  action={addParamsAsHash(
                    orgConfig.url,
                    urlParamsWithTrackingData
                  )}
                  target="_parent"
                >
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={crsfToken}
                  />
                  <StyledStrokeButton
                    border={1}
                    isFancy={isFancy}
                    aria-label={`${signUp ? 'Sign up' : 'Log in'}  with ${
                      orgConfig.org
                    }`}
                    variant="secondary"
                    type="submit"
                    onClick={() => {
                      const defaultTracking = {
                        context: `oauth_${orgConfig.org}_${
                          signUp ? 'register' : 'login'
                        }`,
                        target: 'oauth_button',
                      };
                      trackUserClick({
                        ...trackingData,
                        ...defaultTracking,
                      });
                    }}
                  >
                    <SocialLogo org={orgConfig.org} />
                    {titlePrefix && (
                      <span className={cx(classNames.buttonTitle)}>
                        {`${titlePrefix} ${orgConfig.org}`}
                      </span>
                    )}
                  </StyledStrokeButton>
                </form>
              </OAuthListItem>
            );
          })}
        </>
      </OAuthButtonsWrapper>
    </Box>
  );
};
